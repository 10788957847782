import * as types from '../../constants/actions';
import { FormAction } from './types';
import { User } from '../../interfaces/models/User';
import { AuthAction } from '../auth/types';

export interface FormState {
  login: {
    error?: string | null;
  };
}

const defaultState: FormState = {
  login: {
    error: null,
  },
};

export default (state: FormState = defaultState, action: FormAction | AuthAction): FormState => {
  switch (action.type) {
    case types.AUTH_ERROR:
      return { ...state, login: { ...state.login, error: action.error } };
    case types.LOGIN:
    case types.LOGOUT:
      return { ...state, login: { ...state.login, error: null } };
    default:
      return state;
  }
};
