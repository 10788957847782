import dayjs, { Dayjs } from 'dayjs';
const burndownTicks = (start_date: string, end_date: string): Dayjs[] => {
  const start = dayjs(start_date);
  const d_start = start.day() - 1; // 1 is Monday
  const true_start = d_start === -1 ? start.add(1, 'day') : start.subtract(d_start, 'day');
  const end = dayjs(end_date);
  let ticks: Array<Dayjs> = [];
  let cnt = 0;
  // do {
  //   // alert(cnt);
  //   let tick = true_start.add(7 * cnt, 'day');
  //   ticks.push(tick.format('MM/DD'));
  //   cnt++;
  // } while (true_start.add(7 * cnt, 'day').isBefore(end));
  while (true_start.add(7 * cnt, 'day').isBefore(end.add(7, 'day'))) {
    // alert(cnt);
    let tick = true_start.add(7 * cnt, 'day');
    ticks.push(tick);
    cnt++;
  }
  return ticks;
};

export default burndownTicks;
