import * as types from '../../constants/actions';
import { ProjectAction } from './types';
import { FormAction } from '../forms/types';
import { Project } from '../../interfaces/models/Projects';
import { Budget } from '../../interfaces/models/Budget';
import { Actually } from '../../interfaces/models/Actually';
import { Statistics } from '../../interfaces/models/Statistics';
import { WeeklyTransition } from '../../interfaces/models/WeeklyTransition';
import { Burndown } from '../../interfaces/models/Burndown';
import { ActualAndBudget } from '../../interfaces/models/ActualAndBudget';

export interface ProjectState {
  projects: Project[];
  project_detail: Project | null;

  project_budget: Budget[];

  project_actually: Actually[];

  project_actual_budget: ActualAndBudget[];

  project_statistics: Statistics[];

  project_activities: string[];

  project_weekly_transition: WeeklyTransition[];

  project_burndowns: Burndown[];
}

const defaultState: ProjectState = {
  projects: [],
  project_detail: null,
  project_budget: [],
  project_actually: [],
  project_actual_budget: [],
  project_statistics: [],
  project_activities: [],
  project_weekly_transition: [],
  project_burndowns: [],
};

export default (
  state: ProjectState = defaultState,
  action: ProjectAction | FormAction,
): ProjectState => {
  switch (action.type) {
    case types.GET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case types.GET_PROJECT_DETAIL:
      return {
        ...state,
        project_detail: action.project_detail,
      };
    case types.GET_PROJECT_BUDGET:
      return {
        ...state,
        project_budget: action.project_budget,
        project_activities: (budgets => {
          if (budgets.length) {
            return budgets.map(item => item.name);
          }
          return [];
        })(action.project_budget),
      };
    case types.GET_PROJECT_ACTUALLY:
      return {
        ...state,
        project_actually: action.project_actually,
      };
    case types.GET_PROJECT_ACTUALLY_BUDGET:
      return {
        ...state,
        project_actual_budget: action.project_actual_budget,
      };
    case types.GET_PROJECT_STATISTICS:
      return {
        ...state,
        project_statistics: action.project_statistics,
      };
    case types.GET_PROJECT_WEEKLY_TRANSITIONS:
      return {
        ...state,
        project_weekly_transition: action.project_weekly_transition,
      };
    case types.GET_PROJECT_BURNDOWNS:
      return {
        ...state,
        project_burndowns: action.burndowns,
      };
    case types.CLEAR_PROJECT_BUDGETS:
      return {
        ...state,
        project_actual_budget: [],
      };
    default:
      return state;
  }
};
