import '../styles/ProjectDetail.css';

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../storeConfiguration';
import { User } from '../interfaces/models/User';
import Layout from './components/Layout';
import StatisticsComponent from './components/StatisticsComponent';
import Status from './components/Status';
import WeeklyChart from './components/WeeklyChart';
import BurndownChart from './components/BurndownChart';
import StackBarChart from './components/StackBarChart';
import ProjectTitle from './components/ProjectTitle';
import { Project } from '../interfaces/models/Projects';
import { getProjectDetail } from '../domain/projects/actions';

interface StateProps {
  isAuthenticated: Boolean;
  user: User | null;
  project: Project | null;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
}

class ProjectDetails extends Component<RouteComponentProps<any> & StateProps & DispatchProps> {
  componentDidMount() {
    this.props.dispatch(getProjectDetail(this.props.match.params.id));
  }

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="detail--container d-flex d-flex flex-column">
            <div className="detail--header">
              <ProjectTitle projectId={this.props.match.params.id} />
            </div>
            <div className="detail--project-status">
              <div className="container-flux">
                <div className="row">
                  <div className="detail--status col-7">
                    <Status project={this.props.project} />
                  </div>
                  <div className="detail--status-actual col-5">
                    {/* <StackBarChart projectId={this.props.match.params.id} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="detail--statistics">
              <StatisticsComponent projectId={this.props.match.params.id} />
            </div>
            <div className="detail--weekly-chart">
              <BurndownChart projectId={this.props.match.params.id} />
            </div>
            <div className="detail--weekly-chart">
              <WeeklyChart projectId={this.props.match.params.id} />
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  project: state.projects.project_detail,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDetails);
