import '../../styles/Toolbar.css';
import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import { push } from 'connected-react-router';
import { logout } from '../../domain/auth/actions';

interface StateProps {
  isAuthenticated: Boolean;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
  login?: () => void;
  logout?: () => void;
}

class Toolbar extends Component<StateProps & DispatchProps> {
  render() {
    return (
      <React.Fragment>
        <div className="toolbar d-flex">
          {!this.props.isAuthenticated ? (
            <a className="toolbar--logout align-self-center ml-auto" onClick={this.props.login}>
              Login
            </a>
          ) : (
            <a className="toolbar--logout align-self-center ml-auto" onClick={this.props.logout}>
              Logout
            </a>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
  login: () => dispatch(push('/login')),
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Toolbar);
