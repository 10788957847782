import axios from 'axios';
import { store } from '../storeConfiguration';
import FormData from 'form-data';
import { authError } from '../domain/forms/actions';
import { ProjectStatisticsSearchQuery } from '../interfaces/api/Project';
import { BurndownPlot } from '../interfaces/models/BurndownPlot';
import { CreateBurndown } from '../interfaces/form/CreateBurndown';
import { EditBurndown } from '../interfaces/form/EditBurndown';

/** Utility functions */
function buildQuery(params: any) {
  const path = Object.keys(params)
    .filter(key => [undefined, null, ''].filter(o => o === params[key]).length === 0)
    .map(key => `${key}=${params[key]}`)
    .filter(item => item != null);
  return path ? `?${path.join('&')}` : '';
}

/** Interceptors */
axios.interceptors.request.use(config => {
  const authState = store.getState().auth;
  const token = authState.token;
  config.headers.Authorization = `Bearer ${token}`;

  console.debug(`Calling API: ${config.url}`);

  if (process.env.REACT_APP_BASE_URL) {
    config.baseURL = process.env.REACT_APP_BASE_URL;
  }

  return config;
});

axios.interceptors.response.use(
  res => res,
  error => {
    console.error(error.response);
    if (!error.response || !/application\/json/gi.test(error.response.headers['content-type'])) {
      /** Not json, i.e. logic errors, binary string... */
      console.error(error);

      if (error.response) {
        console.error(error.response);
        error.response.data = {
          error: 'Server Error',
          message:
            "You have encountered an unknown error. Please contact the site's administrators",
        };
      }
      return Promise.reject(error);
    }

    if (error.response.status >= 400 && error.response.status <= 499) {
      // JSON Response with status from 400 to 499
      console.error(error.response);
      const err = error.response.data;
      if ((err.error as string).toLowerCase() === 'unauthorized') {
        store.dispatch(authError(err.message));
      }
    }

    return Promise.reject(error);
  },
);

/** Repositories */
export const Auth = {
  checkCurrentAuthn: () => axios.post('/api/auth/hello'),
  login: (email: string, password: string) =>
    axios.post('/api/auth/login', {
      email,
      password,
    }),
};

export const Project = {
  getProjects: (
    name?: string,
    status?: number | string,
    start_date?: string | undefined,
    end_date?: string | undefined,
  ) => {    
    if(status == -1) {
      status = undefined;
    }
    return axios.get(
      `/api/projects${buildQuery({
        name,
        status,
        start_date,
        end_date,
      })}`,
    )},
  getProjectDetail: (id: number) => axios.get(`/api/projects/${id}`),
  getProjectActually: (id: number) => axios.get(`/api/projects/${id}/actually`),
  getProjectBudget: (id: number) => axios.get(`/api/projects/${id}/budget`),
  getProjectStatistics: (id: number, options?: ProjectStatisticsSearchQuery) =>
    axios.get(`/api/projects/${id}/statistics`, { params: options }),
  getProjectWeeklyTransition: (id: number) => axios.get(`/api/projects/${id}/weekly_transition`),
  getProjectBurndowns: (id: number) => axios.get(`/api/projects/${id}/burndowns`),
  getBurndownPlots: (id: number) => axios.get(`/api/burndowns/${id}/burndown_plots`),
  plotBurndown: (plot: BurndownPlot) =>
    axios.post(`/api/burndowns/${plot.burndown_id}/burndown_plots`, plot),
  deletePlotBurndown: (plot: BurndownPlot) =>
    axios.delete(`/api/burndowns/${plot.burndown_id}/burndown_plots/${plot.plot_at}`),
  createBurndown: (burndown: CreateBurndown, project_id: number) =>
    axios.post(`/api/projects/${project_id}/burndowns`, burndown),
  updateBurndown: (burndown: EditBurndown) =>
    axios.patch(`/api/burndowns/${burndown.burndown_id}`, burndown),
  deleteBurndown: (id: number) => axios.delete(`/api/burndowns/${id}`),
};

export const Mock = {
  test: () => axios.get('/mock/getUser'),
};
