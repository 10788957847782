import '../../styles/FilterResult.css';
import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';

import { Project } from '../../interfaces/models/Projects';
import ProjectList from '../components/ProjectList';
import ProjectItem from '../components/ProjectItem';

interface StateProps {
  isAuthenticated: Boolean;

  projects: Project[];
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
}

class FilterResult extends Component<StateProps & DispatchProps> {
  render() {
    return (
      <div className="filter--container-result d-flex flex-column">
        <ProjectList>
          {this.props.projects.map(project => (
            <ProjectItem key={project.id} project={project} />
          ))}
        </ProjectList>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
  projects: state.projects.projects,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterResult);
