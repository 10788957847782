import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Project } from '../../interfaces/models/Projects';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

interface DispatchProps {
  dispatch?: Dispatch<any>;
}

type ComponentProps = {
  key: number;
  project: Project;
} & DispatchProps;

class ProjectItem extends Component<ComponentProps> {
  render() {
    const status = parseInt(this.props.project.status, 10) === 1 ? 'On Going' : 'Finished';

    return (
      <TableRow key={this.props.project.id}>
        <TableCell component="th" scope="row">
          {this.props.project.name}
        </TableCell>
        <TableCell align="center">{this.props.project.budget}</TableCell>
        <TableCell align="center">{this.props.project.actually}</TableCell>
        <TableCell align="center">{status}</TableCell>
        <TableCell align="center">
          <Button
            variant="outlined"
            onClick={() => {
              this.props.dispatch!(push(`/projects/${this.props.project.id}/details`));
            }}
          >
            Detail
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
  dispatch,
});

export default connect(mapDispatchToProps)(ProjectItem);
