import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../storeConfiguration';
import { User } from '../interfaces/models/User';
import { Link } from 'react-router-dom';
import Layout from './components/Layout';
import { ThunkDispatch } from 'redux-thunk';

interface StateProps {
  isAuthenticated: Boolean;
  user: User | null;
}

interface DispatchProps {
  dispatch: Dispatch<any>;
}

class Home extends Component<StateProps & DispatchProps> {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {this.props.isAuthenticated ? (
            <div>You are authenticated as {this.props.user && this.props.user.email}</div>
          ) : (
            <div>
              You are not authenticated. Click <Link to="/login">here</Link> to login
            </div>
          )}
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
