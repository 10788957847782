import * as types from '../../constants/actions';
import { Project as ProjectAPI } from '../../network/Api';
import { ActionCreator, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { ProjectAction } from './types';
import { AppState } from '../../storeConfiguration';
import { ThunkDispatch } from 'redux-thunk';
import { Project } from '../../interfaces/models/Projects';
import { Budget } from '../../interfaces/models/Budget';
import { Actually } from '../../interfaces/models/Actually';
import { Statistics } from '../../interfaces/models/Statistics';
import { WeeklyTransition } from '../../interfaces/models/WeeklyTransition';
import { Burndown } from '../../interfaces/models/Burndown';
import { BurndownPlot } from '../../interfaces/models/BurndownPlot';
import { BUDGETS } from '../../constants/budgets';
import { ActualAndBudget } from '../../interfaces/models/ActualAndBudget';
import { ProjectStatisticsSearchQuery } from '../../interfaces/api/Project';
import { CreateBurndown } from '../../interfaces/form/CreateBurndown';
import { EditBurndown } from '../../interfaces/form/EditBurndown';

export const projectsAction: ActionCreator<ProjectAction> = (projects: Project[]) => ({
  type: types.GET_PROJECTS,
  projects,
});

export const projectDetailAction: ActionCreator<ProjectAction> = (project_detail: Project) => ({
  type: types.GET_PROJECT_DETAIL,
  project_detail,
});

export const projectBudgetAction: ActionCreator<ProjectAction> = (project_budget: Budget[]) => ({
  type: types.GET_PROJECT_BUDGET,
  project_budget,
});

export const clearProjectBudgetAction: ActionCreator<ProjectAction> = () => ({
  type: types.CLEAR_PROJECT_BUDGETS,
});

export const projectActuallyAction: ActionCreator<ProjectAction> = (
  project_actually: Actually[],
) => ({
  type: types.GET_PROJECT_ACTUALLY,
  project_actually,
});

export const projectActuallyAndBudgetAction: ActionCreator<ProjectAction> = (
  project_actual_budget: ActualAndBudget[],
) => ({
  type: types.GET_PROJECT_ACTUALLY_BUDGET,
  project_actual_budget,
});

export const projectStatisticsAction: ActionCreator<ProjectAction> = (
  project_statistics: Statistics[],
) => ({
  type: types.GET_PROJECT_STATISTICS,
  project_statistics,
});

export const projectWeeklyTransitionAction: ActionCreator<ProjectAction> = (
  project_weekly_transition: WeeklyTransition[],
) => ({
  type: types.GET_PROJECT_WEEKLY_TRANSITIONS,
  project_weekly_transition,
});

export const projectBurndownsAction: ActionCreator<ProjectAction> = (burndowns: Burndown[]) => ({
  type: types.GET_PROJECT_BURNDOWNS,
  burndowns,
});
interface GetProjectsResponse {
  data: Project[];
}

export const getProjects = (
  name?: string,
  status?: number | string,
  start_date?: string | undefined,
  end_date?: string | undefined,
): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
  ) =>
    ProjectAPI.getProjects(name, status, start_date, end_date)
      .then((res: AxiosResponse<GetProjectsResponse>) => {
        dispatch(projectsAction(res.data.data));
      })
      .catch(console.error);

interface GetProjectDetailResponse {
  data: Project;
}

export const getProjectDetail = (
  id: number,
): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
  ) =>
    ProjectAPI.getProjectDetail(id)
      .then((res: AxiosResponse<GetProjectDetailResponse>) => {
        dispatch(projectDetailAction(res.data.data));
      })
      .catch(console.error);

interface GetProjectBudgetResponse {
  data: Budget[];
}

export const getProjectBudget = (
  id: number,
): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
  ) =>
    ProjectAPI.getProjectBudget(id)
      .then((res: AxiosResponse<GetProjectBudgetResponse>) => {
        dispatch(projectBudgetAction(res.data.data));
      })
      .catch(console.error);

interface GetProjectActuallyResponse {
  data: Actually[];
}

export const getProjectActually = (
  id: number,
): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
  ) =>
    ProjectAPI.getProjectActually(id)
      .then((res: AxiosResponse<GetProjectActuallyResponse>) => {
        dispatch(projectActuallyAction(res.data.data));
      })
      .catch(console.error);

export const getProjectActuallyAndBudget = (
  id: number,
): ThunkAction<void, AppState, undefined, Action> => async dispatch =>
    ProjectAPI.getProjectActually(id)
      .then((actualRes: AxiosResponse<GetProjectActuallyResponse>) => {
        dispatch(projectActuallyAction(actualRes.data.data));
        ProjectAPI.getProjectBudget(id)
          .then((budgetRes: AxiosResponse<GetProjectBudgetResponse>) => {
            console.log(budgetRes);
            dispatch(projectBudgetAction(budgetRes.data.data));
          })
          .catch(console.error);
      })
      .catch(console.error);

interface GetProjectStatisticsResponse {
  data: Statistics[];
}

export const getProjectStatistics = (
  id: number,
  options?: ProjectStatisticsSearchQuery,
): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
  ) =>
    ProjectAPI.getProjectStatistics(id, options)
      .then((res: AxiosResponse<GetProjectStatisticsResponse>) => {
        dispatch(projectStatisticsAction(res.data.data));
      })
      .catch(console.error);

interface GetProjectWeeklyTransitionResponse {
  data: WeeklyTransition[];
}

export const getProjectWeeklyTransition = (
  id: number,
): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) =>
ProjectAPI.getProjectWeeklyTransition(id)
  .then((res: AxiosResponse<GetProjectWeeklyTransitionResponse>) => {
    dispatch(projectWeeklyTransitionAction(res.data.data));
  })
  .catch(console.error);

type GetProjectBurndownsResponse = {
  data: Burndown[];
};
export const getProjectBurndowns = (id: number) => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) => {
  const res: AxiosResponse<GetProjectBurndownsResponse> = await ProjectAPI.getProjectBurndowns(id);
  dispatch(projectBurndownsAction(res.data.data));
};

export const requestPlotBurndown = (plot: BurndownPlot, project_id: number) => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) => {
  const res: AxiosResponse<any> = await ProjectAPI.plotBurndown(plot);
  dispatch(getProjectBurndowns(project_id));
};

export const requestDeletePlotBurndown = (plot: BurndownPlot, project_id: number) => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) => {
  const res: AxiosResponse<any> = await ProjectAPI.deletePlotBurndown(plot);
  dispatch(getProjectBurndowns(project_id));
};

export const requestCreateBurndown = (burndown: CreateBurndown, project_id: number) => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) => {
  const res: AxiosResponse<any> = await ProjectAPI.createBurndown(burndown, project_id);
  dispatch(getProjectBurndowns(project_id));
};

type RequestUpdateBurndownResponse = {
  status: 'success' | 'failed';
};
export const requestUpdateBurndown = (burndown: EditBurndown, project_id: number) => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) => {
  const res: AxiosResponse<RequestUpdateBurndownResponse> = await ProjectAPI.updateBurndown(
    burndown,
  );
  dispatch(getProjectBurndowns(project_id));
};

type RequestDeleteBurndownResponse = {
  status: 'success' | 'failed';
};
export const requestDeleteBurndown = (burndown_id: number, project_id: number) => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) => {
  const res: AxiosResponse<RequestDeleteBurndownResponse> = await ProjectAPI.deleteBurndown(
    burndown_id,
  );
  dispatch(getProjectBurndowns(project_id));
};
