import { createBrowserHistory } from 'history';

import { RouterState, connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import authReducer, { AuthState } from './domain/auth/reducer';
import formReducer, { FormState } from './domain/forms/reducer';
import projectsReducer, { ProjectState } from './domain/projects/reducer';

export interface AppState {
  router: RouterState;
  auth: AuthState;
  form: FormState;

  projects: ProjectState;
}

export const history = createBrowserHistory();

export const rootReducer = combineReducers<AppState>({
  router: connectRouter(history),
  auth: authReducer,
  form: formReducer,
  projects: projectsReducer,
});

const middlewares = [
  thunk,
  routerMiddleware(history),
  ...(process.env.NODE_ENV === 'production' ? [] : [require('redux-logger').logger]),
];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
