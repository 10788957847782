import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableHead from '@material-ui/core/TableHead';

import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { push } from 'connected-react-router';
class ProjectList extends Component {
  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Budget</TableCell>
              <TableCell align="center">Actually</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Detail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.props.children}</TableBody>
        </Table>
      </Paper>
    );
  }
}

export default ProjectList;
