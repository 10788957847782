import * as types from '../../constants/actions';
import { AuthAction } from './types';
import { User } from '../../interfaces/models/User';
import { FormAction } from '../forms/types';

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: Boolean;
  user: User | null;
  token: string | null;
}

const { token } = (() => {
  try {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      return {
        token: tokenString,
      };
    }
  } catch (error) {
    localStorage.clear();
  }
  return { token: null };
})();

const defaultState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  token,
};

export default (state: AuthState = defaultState, action: AuthAction | FormAction): AuthState => {
  switch (action.type) {
    case types.INITIALIZED: {
      return {
        ...state,
        isInitialized: true,
      };
    }
    case types.INITIALIZED_WITH_LOGIN: {
      const token = localStorage.getItem('token');
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        user: action.user,
        token,
      };
    }
    case types.LOGIN:
      localStorage.setItem('token', action.token as any);
      localStorage.setItem('user', JSON.stringify(action.user));
      return {
        ...state,
        isAuthenticated: action.user !== null,
        user: action.user || null,
        token: action.token || null,
      };
    case types.LOGOUT:
    case types.AUTH_ERROR:
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
};
