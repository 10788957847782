import dayjs from 'dayjs';
import { BurndownPlot } from '../interfaces/models/BurndownPlot';

const burndownActualData = (
  x_ticks: string[],
  plots: BurndownPlot[],
): (number | null | undefined)[] => {
  const result = x_ticks.map(v => {
    const founded = plots.find(o => {
      const date_string = dayjs(o.plot_at).format('MM/DD');
      return date_string === v;
    });
    return founded ? founded.remaining : null;
  });
  return result;
};

export default burndownActualData;
