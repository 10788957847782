import '../../styles/BurndownChart.css';
import '../../styles/Spacer.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import {
  getProjectBurndowns,
  requestPlotBurndown,
  requestDeletePlotBurndown,
  requestCreateBurndown,
  requestUpdateBurndown,
  requestDeleteBurndown,
} from '../../domain/projects/actions';
import { Line } from 'react-chartjs-2';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import isNumber from '../../utils/isNumber';
import burndownTicks from '../../utils/burndownTicks';
import burndownIdealData from '../../utils/burndownIdealData';
import burndownActualData from '../../utils/burndownActualData';
import { BurndownPlot } from '../../interfaces/models/BurndownPlot';
import { CreateBurndown } from '../../interfaces/form/CreateBurndown';
import { EditBurndown } from '../../interfaces/form/EditBurndown';
import { FormControl, MenuItem } from '@material-ui/core';

type ComponentProps = {
  projectId: number;
};

type State = {
  plot_date: string;
  plot_remaining: number | string;
};

type DialogState = CreateBurndown & {
  create_dialog_open: boolean;
};

type EditDialogState = EditBurndown & {
  edit_dialog_open: boolean;
};

type DeleteDialogState = {
  delete_dialog_open: boolean;
  target_burndown_id: number | null;
};

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#009688',
    },
  },
});

const BurndownChart: React.FC<ComponentProps> = props => {
  const dispatch = useDispatch();
  const { project_burndowns } = useSelector((state: AppState) => ({
    project_burndowns: state.projects.project_burndowns,
  }));
  useEffect(() => {
    dispatch(getProjectBurndowns(props.projectId));
  }, []);

  // Plot State
  const [state, setState] = useState<State>({
    plot_date: '',
    plot_remaining: 0,
  });

  // Action for Plot
  const handleChangePlotDate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(state => ({ ...state, plot_date: e.target.value }));
  };

  const onClickAdd = () => {
    setDialogState(state => ({ ...state, create_dialog_open: true }));
  };

  // State for creating a new burndown dialog.
  const initial_dialog_state = {
    create_dialog_open: false,
    burndown_name: '',
    burndown_estimation: 0,
    burndown_start_date: '',
    burndown_end_date: '',
  };

  // Action for creating
  const [dialog_state, setDialogState] = useState<DialogState>(initial_dialog_state);

  const handleChangeDialogForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setDialogState(state => ({ ...state, [e.target.name]: e.target.value }));
  };

  const handleClose = (e: any) => {
    setDialogState(state => ({ ...state, create_dialog_open: false }));
  };

  const handleCreate = (e: any) => {
    dispatch(
      requestCreateBurndown(
        {
          burndown_name: dialog_state.burndown_name,
          burndown_estimation: dialog_state.burndown_estimation,
          burndown_start_date: dialog_state.burndown_start_date,
          burndown_end_date: dialog_state.burndown_end_date,
        },
        props.projectId,
      ),
    );
    setDialogState(state => initial_dialog_state);
  };

  // State for editing burndown dialog.
  const initial_edit_dialog_state = {
    edit_dialog_open: false,
    burndown_id: 0,
    burndown_name: '',
    burndown_estimation: 0,
    burndown_start_date: '',
    burndown_end_date: '',
  };

  const [edit_dialog_state, setEditDialogState] = useState<EditDialogState>(
    initial_edit_dialog_state,
  );

  // Action for Edit
  const handleClickEdit = (target_burndown_id: number) => {
    const burndown = project_burndowns.find(v => v.id === target_burndown_id);
    if (!burndown) {
      console.error('@handleClickEdit: Burndown does not exist');
      return;
    }
    setEditDialogState(state => ({
      ...state,
      edit_dialog_open: true,
      burndown_id: burndown.id,
      burndown_name: burndown.name,
      burndown_estimation: burndown.estimation,
      burndown_start_date: burndown.start_date,
      burndown_end_date: burndown.end_date,
    }));
  };

  const handleChangeEditDialogForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setEditDialogState(state => ({ ...state, [e.target.name]: e.target.value }));
  };

  const handleUpdate = () => {
    dispatch(
      requestUpdateBurndown(
        {
          burndown_id: edit_dialog_state.burndown_id,
          burndown_name: edit_dialog_state.burndown_name,
          burndown_estimation: edit_dialog_state.burndown_estimation,
          burndown_start_date: edit_dialog_state.burndown_start_date,
          burndown_end_date: edit_dialog_state.burndown_end_date,
        },
        props.projectId,
      ),
    );
    setEditDialogState(state => ({ ...state, edit_dialog_open: false }));
  };

  const handleEditClose = () => setEditDialogState(initial_edit_dialog_state);

  // State for deleting dialog
  const [delete_dialog_state, setDeleteDialogState] = useState<DeleteDialogState>({
    delete_dialog_open: false,
    target_burndown_id: null,
  });
  // Action for deleting
  const handleClickDelete = (target_burndown_id: number) => {
    setDeleteDialogState(state => ({
      ...state,
      delete_dialog_open: true,
      target_burndown_id,
    }));
  };

  const handleDeleteClose = () => {
    setDeleteDialogState(state => ({ ...state, delete_dialog_open: false }));
  };

  const handleDelete = () => {
    if (delete_dialog_state.target_burndown_id === null) {
      return;
    }
    dispatch(requestDeleteBurndown(delete_dialog_state.target_burndown_id, props.projectId));
    setDeleteDialogState(state => ({ ...state, delete_dialog_open: false }));
  };

  // Options for a chart.
  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    spanGaps: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'week',
            autoSkip: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="chart--container d-flex flex-column">
        <span className="chart--header">Burn Down Chart</span>
        <span className="burndown--button-add">
          <Fab size="small" color="secondary" aria-label="Add" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
        </span>
        {project_burndowns.map(burndown => {
          const x_ticks = burndownTicks(burndown.start_date, burndown.end_date);
          const x_tick_labels = x_ticks.map(d => d.format('MM/DD'));
          const actualData = burndownActualData(x_tick_labels, burndown.burndown_plots);
          return (
            <div>
              <div className="d-flex flex-column">
                <div className="chart--subheader">
                  {burndown.name} : {burndown.start_date} - {burndown.end_date}{' '}
                  <IconButton aria-label="edit" onClick={() => handleClickEdit(burndown.id)}>
                    <EditIcon fontSize="small" style={{ marginTop: '-4px' }} />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={() => handleClickDelete(burndown.id)}>
                    <DeleteIcon fontSize="small" style={{ marginTop: '-4px' }} />
                  </IconButton>
                </div>
                <div className="col-12">
                  <div className="spacer-v-32" />
                  <Line
                    options={options}
                    data={() => {
                      return {
                        labels: x_tick_labels,
                        datasets: [
                          {
                            label: burndown.name,
                            backgroundColor: 'rgba(192,100,75,0.4)',
                            borderWidth: 1,
                            lineTension: 0,
                            data: actualData,
                          },
                          {
                            label: `${burndown.name}-ideal`,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderWidth: 1,
                            lineTension: 0,
                            data: burndownIdealData(burndown.estimation, x_ticks.length),
                          },
                        ],
                      };
                    }}
                  />
                </div>
                <div className="chart--total d-flex">
                  <span className="chart--total-title d-flex align-self-center ml-auto">
                    Total Amount
                  </span>
                  <span className="chart--total-value">{burndown.estimation}</span>
                </div>
              </div>
              <div>
                <MuiThemeProvider theme={theme}>
                  <div
                    className="chart--btn-plot d-flex ml-auto align-items-center"
                    style={{ flexWrap: 'wrap' }}
                  >
                    <span className="chart--plot-note ">Plot date and remain</span>
                    <FormControl
                      style={{ marginTop: '8px', marginRight: '16px', minWidth: '80px' }}
                    >
                      <InputLabel htmlFor="id-plot_date">Date</InputLabel>
                      <Select
                        value={state.plot_date}
                        onChange={handleChangePlotDate}
                        inputProps={{ name: 'plot_date', id: 'id-plot_date' }}
                      >
                        <MenuItem value="" />
                        {burndownTicks(burndown.start_date, burndown.end_date).map(v => (
                          <MenuItem key={v.format('YYYY-MM-DD')} value={v.format('YYYY-MM-DD')}>
                            {v.format('MM/DD')}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl style={{ marginRight: '16px' }}>
                      <TextField
                        label="Remaining"
                        margin="normal"
                        type="number"
                        value={
                          isNumber(state.plot_remaining)
                            ? state.plot_remaining
                            : String(state.plot_remaining)
                        }
                        onChange={e => {
                          setState({
                            plot_date: state.plot_date,
                            plot_remaining: parseInt(e.target.value, 10),
                          });
                        }}
                      />
                    </FormControl>
                    <Button
                      style={{ marginRight: '16px' }}
                      className="chart--plot-btn"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        if (state.plot_date === '') {
                          return;
                        }
                        if (!isNumber(state.plot_remaining)) {
                          return;
                        }
                        let data: BurndownPlot = {
                          plot_at: state.plot_date,
                          burndown_id: burndown.id,
                        };
                        data = { ...data, remaining: state.plot_remaining as number };
                        dispatch(requestPlotBurndown(data, props.projectId));
                      }}
                    >
                      Plot
                    </Button>
                    <Button
                      className="chart--plot-btn"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        if (state.plot_date === '') {
                          return;
                        }
                        const data: BurndownPlot = {
                          plot_at: state.plot_date,
                          burndown_id: burndown.id,
                        };
                        dispatch(requestDeletePlotBurndown(data, props.projectId));
                      }}
                    >
                      Delete
                    </Button>
                    <div />
                  </div>
                </MuiThemeProvider>
              </div>
            </div>
          );
        })}
      </div>
      {/* Dialog for creating a new burndown */}
      <Dialog
        open={dialog_state.create_dialog_open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create a new burndown chart</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter name, estimation, start date and end date of burndown
          </DialogContentText>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="burndown_name"
              name="burndown_name"
              label="Name"
              type="text"
              value={dialog_state.burndown_name}
              onChange={handleChangeDialogForm}
            />
            <TextField
              margin="dense"
              id="burndown_estimation"
              name="burndown_estimation"
              value={dialog_state.burndown_estimation}
              label="Estimation"
              type="number"
              style={{ marginLeft: '2rem' }}
              onChange={handleChangeDialogForm}
            />
          </div>
          <div>
            <TextField
              label="burndown_start_date"
              name="burndown_start_date"
              value={dialog_state.burndown_start_date}
              type="date"
              defaultValue="2017-05-24"
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeDialogForm}
            />
            <TextField
              style={{ marginLeft: '2rem' }}
              label="burndown_end_date"
              name="burndown_end_date"
              value={dialog_state.burndown_end_date}
              type="date"
              defaultValue="2017-09-24"
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeDialogForm}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing a burndown */}
      <Dialog
        open={edit_dialog_state.edit_dialog_open}
        onClose={handleEditClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit the burndown chart</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please update name, estimation, start date and end date of burndown
          </DialogContentText>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="burndown_name"
              name="burndown_name"
              label="Name"
              type="text"
              value={edit_dialog_state.burndown_name}
              onChange={handleChangeEditDialogForm}
            />
            <TextField
              margin="dense"
              id="burndown_estimation"
              name="burndown_estimation"
              value={edit_dialog_state.burndown_estimation}
              label="Estimation"
              type="number"
              style={{ marginLeft: '2rem' }}
              onChange={handleChangeEditDialogForm}
            />
          </div>
          <div>
            <TextField
              label="burndown_start_date"
              name="burndown_start_date"
              value={edit_dialog_state.burndown_start_date}
              type="date"
              defaultValue="2017-05-24"
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeEditDialogForm}
            />
            <TextField
              style={{ marginLeft: '2rem' }}
              label="burndown_end_date"
              name="burndown_end_date"
              value={edit_dialog_state.burndown_end_date}
              type="date"
              defaultValue="2017-09-24"
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeEditDialogForm}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for deleting a burndown */}
      <Dialog
        open={delete_dialog_state.delete_dialog_open}
        onClose={handleDeleteClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Burndown</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to delete this burndown chart?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BurndownChart;
