import '../../styles/Layout.css';

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import Toolbar from './Toolbar';

class Layout extends Component {
  render() {
    return (
      <div className="layout--container container-fluid">
        <div className="row">
          <div className="layout--sidebar col-lg-2 col-sm-3 p-0">
            <SideBar />
          </div>
          <div className="layout--main col-lg-10 p-0 d-flex flex-column">
            <div className="layout--toolbar">
              <Toolbar />
            </div>
            <div className="layout--content">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
