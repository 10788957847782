import '../../styles/Filter..css';
import React, { FunctionComponent, useState } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { getProjects } from '../../domain/projects/actions';
import { ThunkDispatch } from 'redux-thunk';

interface State {
  status: number | string;
  name?: string;
  start_date?: string;
  end_date?: string;
}

interface StateProps {
  isAuthenticated: Boolean;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
  getProjects: (
    name?: string,
    status?: number | string,
    start_date?: string | undefined,
    end_date?: string | undefined,
  ) => void;
}

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#009688',
    },
  },
});

type ComponentProps = {} & StateProps & DispatchProps;

const Filter: FunctionComponent<ComponentProps> = props => {
  const initialState = {
    name: '',
    status: 1,
    start_date: undefined,
    end_date: undefined,
  };
  const [state, setState] = useState<State>(initialState);
  const submitFilter = () => {
    const {status, name, start_date, end_date } = state;
    props.getProjects(
      name,
      status,
      start_date ? start_date : undefined,
      end_date ? end_date : undefined,
    );
  };
  return (
    <div className="filter--container d-flex flex-column">
      <span className="filter--header">Enter and/or choose conditions for filtering project.</span>
      <div className="filter--items d-flex flex-row">
        <div className="filter--name">
          <FormControl className="d-flex">
            <TextField
              value={state.name}
              label="Name"
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                // can't access to e.currentTarget.value in a callback of setState()
                // because 'e' is null on that timing.
                const value = e.currentTarget.value;
                setState(state => ({ ...state, name: value }));
              }}
              onKeyPress={e => e.key === 'Enter' && submitFilter()}
            />
          </FormControl>
        </div>
        <div className="filter--status">
          <FormControl className="d-flex">
            <InputLabel shrink> Status </InputLabel>
            <Select
              value={state.status}
              onChange={e => {
                const value = e.target.value;
                setState(state => ({ ...state, status: value }));
              }}
            >
              <MenuItem value={-1}>None</MenuItem>
              <MenuItem value={0}>Finished</MenuItem>
              <MenuItem value={1}>On Going</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="filter--release-from">
          <form noValidate>
            <TextField
              id="date"
              label="Release Date From"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                const value = e.currentTarget.value;
                setState(state => ({ ...state, start_date: value }));
              }}
              onKeyPress={e => e.key === 'Enter' && submitFilter()}
            />
          </form>
        </div>
        <div className="filter--release-to">
          <form noValidate>
            <TextField
              id="date"
              label="Release Date To"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                const value = e.currentTarget.value;
                setState(state => ({ ...state, end_date: value }));
              }}
              onKeyPress={e => e.key === 'Enter' && submitFilter()}
            />
          </form>
        </div>
      </div>
      <div className="filter--button d-flex">
        <div className="filter--btn-clear d-flex ml-auto">
          <div>
            <Button className="filter--btn-clear" onClick={e => setState(state => initialState)}>
              Clear
            </Button>
          </div>
          <div className="filter--btn-get">
            <MuiThemeProvider theme={theme}>
              <Button
                className="filter--btn-get align-self-end"
                variant="contained"
                color="secondary"
                onClick={e => submitFilter()}
              >
                Get Result
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
  getProjects: (
    name?: string,
    status?: number | string,
    start_date?: string | undefined,
    end_date?: string | undefined,
  ) => dispatch(getProjects(name, status, start_date, end_date)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filter);
