import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min';
import 'bootstrap';

import React, { FunctionComponent } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';

import Home from './screens/Home';
import Login from './screens/Login';
import ProjectList from './screens/ProjectList';
import ProjectDetails from './screens/ProjectDetails';
import { AppState } from './storeConfiguration';
import { Action } from 'redux';
import Initialization from './screens/Initialization';

interface AppProps {
  history: History;
}

interface StateProps {
  isInitialized: boolean;
  isAuthenticated: Boolean;
  error?: string | null;
}

interface DispatchProps {
  dispatch?: ThunkDispatch<AppState, undefined, Action>;
}

const Router: FunctionComponent<AppProps & StateProps & DispatchProps> = props => {
  if (!props.isInitialized) {
    return (
      <ConnectedRouter history={props.history}>
        <React.Fragment>
          <Initialization />
        </React.Fragment>
      </ConnectedRouter>
    );
  }
  if (!props.isAuthenticated) {
    return (
      <ConnectedRouter history={props.history}>
        <React.Fragment>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Redirect to="/login" />
          </Switch>
        </React.Fragment>
      </ConnectedRouter>
    );
  }
  return (
    <ConnectedRouter history={props.history}>
      <React.Fragment>
        <Switch>
          <Route exact path="/index" component={Home} />
          <Route exact path="/projects" component={ProjectList} />
          <Route exact path="/projects/:id/details" component={ProjectDetails} />
          <Redirect to="/index" />
        </Switch>
      </React.Fragment>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  isInitialized: state.auth.isInitialized,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Router);
