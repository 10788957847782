import '../../styles/Status.css';
import React, { Component } from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Budget } from '../../interfaces/models/Budget';
import { Actually } from '../../interfaces/models/Actually';
import { ThunkDispatch } from 'redux-thunk';
import { getProjectActually } from '../../domain/projects/actions';
import { getProjectBudget } from '../../domain/projects/actions';
import { Project } from '../../interfaces/models/Projects';
import moment from 'moment';

interface StateProps {
  start_date: string;
  end_date: string;
  progress: number;
  budget: number;
  first_actually_date: string;
  last_actually_date: string;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
  getProjectActually: (id: number) => void;

  getProjectBudget: (id: number) => void;
}

type ComponentProps = {
  project: Project | null;
} & StateProps &
  DispatchProps;
class Status extends Component<ComponentProps> {
  render() {
    return (
      <div className="status--container d-flex flex-column">
        <span className="status--header d-flex">Status</span>
        <div className="container">
          <div className="container mt-3">
            <div className="row align-items-center">
              <span className="status--title col-2">Progress</span>
              <LinearProgress className="col-9" variant="buffer" value={this.props.progress} />
              <span className="col-1">{Number(this.props.progress.toFixed(0))}%</span>
            </div>
          </div>
          <div className="container mt-1">
            <div className="row align-items-center">
              <span className="status--title col-2">Budget</span>
              <LinearProgress className="w-100 col-9" variant="buffer" value={this.props.budget} />
              <span className="col-1">{Number(this.props.budget.toFixed(0))}%</span>
            </div>
          </div>
          <div className="container mt-1 mb-3">
            <div className="row align-items-center">
              <span className="status--title col-2">Timeline</span>
              <LinearProgress className="col-9" variant="buffer" value={this.props.progress} />
              <span className="col-1">{Number(this.props.progress.toFixed(0))}%</span>
            </div>
          </div>
        </div>

        <div className="status--start-end-date d-flex flex-column">
          <div className="status--start-date container ml-2 mt-2">
            <div className="row">
              <span className="status--title col-3">Start Date:</span>
              <span className="status--title  col-3">{this.props.start_date}</span>
              <span className="status--title col-3">Release Date:</span>
              <span className="status--title  col-3">{this.props.end_date}</span>
            </div>
          </div>
          <div className="status--start-date container ml-2 mt-2">
            <div className="row">
              <span className="status--title col-3">First Actually Date:</span>
              <span className="status--title col-3">{this.props.first_actually_date}</span>
              <span className="status--title col-3">Last Actually Date:</span>
              <span className="status--title col-3">{this.props.last_actually_date}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  start_date: ((project: Project | null) => {
    if (project && project.start_date) {
      return moment(project.start_date).format('MM/DD/YYYY');
    }
    return 'Undefined';
  })(state.projects.project_detail),
  end_date: ((project: Project | null) => {
    if (project && project.end_date) {
      return moment(project.end_date).format('MM/DD/YYYY');
    }
    return 'Undefined';
  })(state.projects.project_detail),
  budget: ((project: Project | null) => {
    return project ? (project.actually / project.budget) * 100 : 0;
  })(state.projects.project_detail),
  progress: ((project: Project | null) => {
    if (project && project.start_date && project.end_date) {
      const start_date = moment(project.start_date);
      const end_date = moment(project.end_date);
      const current_date = moment();

      const total = end_date.diff(start_date, 'days');
      const progress = end_date.diff(current_date, 'days');
      if (total) {
        return ((total - progress) / total) * 100;
      }
      return 100;
    }
    return 0;
  })(state.projects.project_detail),
  first_actually_date: ((project: Project | null) => {
    if (project && project.first_actually_date) {
      return moment(project.first_actually_date).format('MM/DD/YYYY');
    }
    return 'Undefined';
  })(state.projects.project_detail),
  last_actually_date: ((project: Project | null) => {
    if (project && project.last_actually_date) {
      return moment(project.last_actually_date).format('MM/DD/YYYY');
    }
    return 'Undefined';
  })(state.projects.project_detail),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
  getProjectActually: (id: number) => dispatch(getProjectActually(id)),
  getProjectBudget: (id: number) => dispatch(getProjectBudget(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Status);
