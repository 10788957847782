import React, { FunctionComponent, useEffect } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../storeConfiguration';
import { ThunkDispatch } from 'redux-thunk';
import { initialize } from '../domain/auth/actions';

interface StateProps {
  isAuthenticated: Boolean;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
}

const Initialization: FunctionComponent<StateProps & DispatchProps> = props => {
  useEffect(() => {
    props.dispatch(initialize());
  }, []);
  return <div>initilize...</div>;
};

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Initialization);
