import { push } from 'connected-react-router';
import * as types from '../../constants/actions';
import { Auth } from '../../network/Api';
import { User } from '../../interfaces/models/User';
import { ActionCreator, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { AuthAction } from './types';
import { AppState } from '../../storeConfiguration';
import { authError } from '../forms/actions';
import { ThunkDispatch } from 'redux-thunk';

export const initializedWithLogin: ActionCreator<AuthAction> = (user: User) => ({
  type: types.INITIALIZED_WITH_LOGIN,
  user,
});

export const initialized: ActionCreator<AuthAction> = () => ({
  type: types.INITIALIZED,
});
interface HelloResponse {
  isAuthenticated: boolean;
  user?: User;
}
export const initialize = (): ThunkAction<void, AppState, undefined, Action> => async dispatch => {
  Auth.checkCurrentAuthn().then((res: AxiosResponse<HelloResponse>) => {
    if (res.data.isAuthenticated) {
      dispatch(initializedWithLogin(res.data.user));
    } else {
      dispatch(initialized());
    }
  });
};
export const loginAction: ActionCreator<AuthAction> = (user: User, token: string) => ({
  type: types.LOGIN,
  user,
  token,
});

export const logoutAction: ActionCreator<AuthAction> = () => ({
  type: types.LOGOUT,
});

interface LoginResponse {
  user: User;
  token: string;
}

/**
 * Acync action should return ThunkAction.
 * ThunkAction takes 4 generics but they are typical.
 * - First: The type of the returned value of the returned function. It usually is `void`.
 * - Second: Should always be `AppState`.
 * - Third: The type of the extra argument. I don't know it well
 * but since we're not using it, setting it to `undefined` is probably Ok
 * - Fourth: The type of `Action` that would be dispatched.
 */
export const requestLogin = (
  username: string,
  password: string,
): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) =>
  Auth.login(username, password)
    .then((res: AxiosResponse<LoginResponse>) => {
      dispatch(loginAction(res.data.user, res.data.token));
    })
    .then(() => dispatch(push('/index')))
    .catch(error => {
      dispatch(authError('Invalid username or password'));
    });

export const logout = (): ThunkAction<void, AppState, undefined, Action> => async (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
) => {
  dispatch(logoutAction());
  dispatch(push('/login'));
};
