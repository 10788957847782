import '../styles/Login.css';
import { requestLogin } from '../domain/auth/actions';

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AppState } from '../storeConfiguration';
import { Action } from 'redux';

interface State {
  username: string;
  password: string;
}

interface StateProps {
  isAuthenticated: Boolean;
  error?: string | null;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
  login: (username: string, password: string) => void;
}

type ComponentProps = {} & RouteComponentProps & StateProps & DispatchProps;

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#009688',
    },
  },
});
class Login extends Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  render() {
    return (
      <div className="login--bg d-flex justify-content-center">
        <div className="login--container d-flex flex-column">
          <h1 className="login--title-primary align-self-center">YJT</h1>
          <div className="login--box d-flex flex-column border">
            <h2 className="login--title-secondary">Login</h2>
            {this.props.error && <span className="login--title-error">{this.props.error}</span>}
            <div className="login--input d-flex flex-column">
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="outlined-email-input login--form-input"
                  label="Email"
                  type="email"
                  name="username"
                  autoComplete="email"
                  margin="normal"
                  onChange={e => this.setState({ username: e.target.value })}
                  onKeyPress={e =>
                    e.key === 'Enter' && this.props.login(this.state.username, this.state.password)
                  }
                />
                <TextField
                  className="outlined-password-input login--form-input"
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  onChange={e => this.setState({ password: e.target.value })}
                  onKeyPress={e =>
                    e.key === 'Enter' && this.props.login(this.state.username, this.state.password)
                  }
                />

                <Button
                  className="login--btn-submit align-self-end"
                  onClick={e => {
                    const { username, password } = this.state;
                    this.props.login(username, password);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Login
                </Button>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.form.login.error,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
  login: (username: string, password: string) => dispatch(requestLogin(username, password)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
