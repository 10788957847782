import '../../styles/ProjectTitle.css';
import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../storeConfiguration';
import { Action } from 'redux';
import { getProjectDetail } from '../../domain/projects/actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Project } from '../../interfaces/models/Projects';

interface ComponentProps {
  projectId: number;
}

interface StateProps {
  project: Project | null;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
  getProjectDetail: (id: number) => void;
}

class ProjectTitle extends Component<ComponentProps & StateProps & DispatchProps> {
  componentDidMount() {
    this.props.getProjectDetail(this.props.projectId);
  }
  render() {
    return (
      <div className="d-flex flex-column">
        <div className="project-title--path d-flex">
          <span className="project-title--menu align-self-center">Project Analysis</span>
          <span className="project-title--sub align-self-center">> Detail</span>
        </div>
        <div className="project-title--back align-self-start" onClick={e => this.props.dispatch(push('/projects'))}>
          Back
        </div>
        <div className="d-flex">
          <span className="project-title--code align-self-center">XXX01</span>
          <span className="project-title--name align-self-center"> {this.props.project && this.props.project.name} </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  project: state.projects.project_detail,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
  getProjectDetail: (id: number) => dispatch(getProjectDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTitle);
