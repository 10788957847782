import '../../styles/Statistics.css';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Budget } from '../../interfaces/models/Budget';
import { Actually } from '../../interfaces/models/Actually';
import { getProjectStatistics, getProjectBudget } from '../../domain/projects/actions';
import { ThunkDispatch } from 'redux-thunk';
import { Statistics } from '../../interfaces/models/Statistics';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import { ProjectStatisticsSearchQuery } from '../../interfaces/api/Project';

interface StateProps {
  budget: Budget[];
  actual: Actually[];
  statistics: Statistics[];
  activities: string[];
}

interface Options {
  target_month: string;
}
interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
  getProjectStatistics: (id: number, options?: ProjectStatisticsSearchQuery) => void;
}

type ComponentProps = {
  projectId: number;
} & StateProps &
  DispatchProps;

type State = {
  from: string;
  to: string;
};

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#009688',
    },
  },
});

const styles = {
  statisticsToolItem: {
    marginRight: '8px',
  },
};
const StatisticsComponent: FunctionComponent<ComponentProps> = props => {
  const [state, setState] = useState<State>({ from: '', to: '' });
  useEffect(() => {
    props.getProjectStatistics(props.projectId);
  }, []);
  // Calculate total act by monthly based statistics.
  const total_act = props.activities.map(act => {
    let activities = props.statistics.map(statistic => {
      let activity = statistic.activities.find(a => a.name == act);
      return activity ? activity.total_actually : 0;
    });
    return activities.reduce((a, b) => a + b, 0);
  });

  const this_month_from = moment()
    .startOf('month')
    .format('YYYY-MM-DD');
  const this_month_to = moment()
    .endOf('month')
    .format('YYYY-MM-DD');

  const last_month_from = moment()
    .add(-1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');
  const last_month_to = moment()
    .add(-1, 'month')
    .endOf('month')
    .format('YYYY-MM-DD');

  return (
    <div className="statistics--container d-flex flex-column">
      <span className="statistics--header d-flex">Statistics</span>

      <div className="statistics--note">
        "Last Month" and "This Month" buttons update this table immediately.
        <br />
        You can also set a specific period and click "Update" and then get the result.
        <br />
        Empty "from" and "to" give you the entire period data.
      </div>

      <Paper className="statistics--tool">
        <MuiThemeProvider theme={theme}>
          <Button
            style={styles.statisticsToolItem}
            variant="outlined"
            color="primary"
            onClick={() => {
              setState(state => ({ ...state, from: last_month_from, to: last_month_to }));
              props.getProjectStatistics(props.projectId, {
                from: last_month_from,
                to: last_month_to,
              });
            }}
          >
            Last Month
          </Button>
          <Button
            style={styles.statisticsToolItem}
            variant="outlined"
            color="primary"
            onClick={() => {
              setState(state => ({ ...state, from: this_month_from, to: this_month_to }));
              props.getProjectStatistics(props.projectId, {
                from: this_month_from,
                to: this_month_to,
              });
            }}
          >
            This Month
          </Button>
          <TextField
            style={styles.statisticsToolItem}
            label="from"
            type="date"
            defaultValue={state.from}
            InputLabelProps={{ shrink: true }}
            value={state.from}
            onChange={e => setState(state => ({ ...state, from: e.currentTarget.value }))}
          />
          <TextField
            style={styles.statisticsToolItem}
            label="to"
            type="date"
            defaultValue={state.to}
            InputLabelProps={{ shrink: true }}
            value={state.to}
            onChange={e => setState(state => ({ ...state, to: e.currentTarget.value }))}
          />

          <Button
            style={styles.statisticsToolItem}
            variant="contained"
            color="secondary"
            onClick={() =>
              props.getProjectStatistics(props.projectId, { from: state.from, to: state.to })
            }
          >
            Update
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setState(state => ({
                ...state,
                from: '',
                to: '',
              }))
            }
          >
            Clear
          </Button>
        </MuiThemeProvider>
      </Paper>

      <div className="detail--statistics-table ">
        <Paper style={{ overflow: 'scroll' }}>
          <Table padding="dense">
            <TableHead>
              <TableRow style={{ backgroundColor: '#e0f2f1' }}>
                <TableCell align="center" />
                <TableCell align="center">Total</TableCell>
                {props.activities.map(item => (
                  <TableCell
                    key={`activity-name-${item}`}
                    align="center"
                    style={{ padding: '4px 0px 4px', minWidth: '80px' }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={'budget'} hover={true}>
                <TableCell align="center">Budget</TableCell>
                <TableCell align="center">
                  {props.budget.map(item => item.budget).reduce((prev, curr) => prev + curr, 0)}
                </TableCell>
                {props.budget.map(item => (
                  <TableCell key={`budget-${item.name}`} align="center">
                    {item.budget}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow key={'actual'} hover={true} style={{ borderBottom: '2px solid #e0e0e0' }}>
                <TableCell align="center">Total Act.</TableCell>
                <TableCell align="center">
                  {total_act.reduce((prev, curr) => prev + curr, 0)}
                </TableCell>
                {total_act.map((value, index) => (
                  <TableCell key={`total-act-${index}`} align="center">
                    {value}
                  </TableCell>
                ))}
              </TableRow>

              {props.statistics.map(statistic => (
                <TableRow key={statistic.id} hover={true}>
                  <TableCell align="center">{statistic.name}</TableCell>
                  <TableCell align="center">
                    {statistic.activities
                      .map(act => act.total_actually)
                      .reduce((prev, curr) => prev + curr, 0)}
                  </TableCell>
                  {statistic.activities.map(item => (
                    <TableCell key={`${statistic.name}-${item.name}`} align="center">
                      {item.total_actually}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <div className="statistics-button d-flex ml-auto">
        <MuiThemeProvider theme={theme}>
          <Button variant="contained" color="secondary">
            Download
          </Button>
        </MuiThemeProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  budget: state.projects.project_budget,
  actual: state.projects.project_actually,
  statistics: state.projects.project_statistics,
  activities: state.projects.project_activities,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
  getProjectStatistics: (id: number, options?: ProjectStatisticsSearchQuery) => {
    dispatch(getProjectBudget(id));
    dispatch(getProjectStatistics(id, options));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatisticsComponent);
