import '../styles/Projects.css';

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../storeConfiguration';
import Layout from './components/Layout';
import Filter from './components/Filter';
import FilterResult from './components/FilterResult';

interface StateProps {
  isAuthenticated: Boolean;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
}

class ProjectList extends Component<RouteComponentProps & StateProps & DispatchProps> {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="projects--container d-flex d-flex flex-column">
            <span className="projects--header">Project Analysis</span>
            <div className="project--filter">
              <Filter />
            </div>
            <div className="project--filter-result d-flex">
              <FilterResult />
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectList);
