import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../storeConfiguration';
import { connect } from 'react-redux';

import '../../styles/MainMenu.css';
import { push } from 'connected-react-router';

interface StateProps {
  isAuthenticated: Boolean;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
}

class MainMenu extends Component<StateProps & DispatchProps> {
  render() {
    return (
      <div className="main-menu">
        <List component="nav">
          <ListItem button>
            <ListItemText
              primary="Dashboard"
              onClick={() => {
                this.props.dispatch!(push('/'));
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Project Analysis"
              onClick={() => {
                this.props.dispatch!(push('/projects'));
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary="Cost Calculation"
              onClick={() => {
                this.props.dispatch!(push('/projects'));
              }}
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainMenu);
