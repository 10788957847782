import '../../styles/WeeklyChart.css';
import React, { Component } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import { Bar } from 'react-chartjs-2';
import { ThunkDispatch } from 'redux-thunk';
import { getProjectWeeklyTransition } from '../../domain/projects/actions';

interface ComponentProps {
  projectId: number;
}
interface StateProps {
  value: number[];
  label: string[];
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;

  getProjectWeeklyTransition: (id: number) => void;
}

class WeeklyChart extends Component<ComponentProps & StateProps & DispatchProps> {
  static options = {
    responsive: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'week',
          },
          barPercentage: 0.5,
        },
      ],
    },
  };

  componentDidMount() {
    this.props.getProjectWeeklyTransition(this.props.projectId);
  }

  render() {
    return (
      <div className="chart--container d-flex flex-column">
        <span className="chart--header d-flex">Weekly Transition</span>
        <div className="chart-table d-flex">
          <Bar
            width={1000}
            height={200}
            data={{
              labels: this.props.label,
              datasets: [
                {
                  backgroundColor: '#008FF7',
                  borderWidth: 1,
                  data: this.props.value,
                },
              ],
            }}
            redraw
            options={WeeklyChart.options}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  value: state.projects.project_weekly_transition.map(item => item.total_actually),
  label: state.projects.project_weekly_transition.map((item, index) => index + 1 + ''),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
  getProjectWeeklyTransition: (id: number) => dispatch(getProjectWeeklyTransition(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WeeklyChart);
