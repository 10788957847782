import '../../styles/SideBar.css';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../storeConfiguration';
import { Link } from 'react-router-dom';
import MainMenu from './MainMenu';

interface StateProps {
  someState: String;
}

interface DispatchProps {
  dispatch: ThunkDispatch<AppState, undefined, Action>;
}

class SideBar extends Component<StateProps & DispatchProps> {
  render() {
    return (
      <div className="d-flex flex-column">
        <div className="sidebar--header d-flex">
          <span className="sidebar--header-text align-self-center">YJT</span>
        </div>
        <div className="sidebar--menu">
          <MainMenu />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  someState: 'some state',
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, undefined, Action>,
): DispatchProps => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideBar);
