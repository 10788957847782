export const INITIALIZED_WITH_LOGIN = '@auth/INITIALIZED_WITH_LOGIN';
export const INITIALIZED = '@auth/INITIALIZED';
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const AUTH_ERROR = '@auth/ERROR';
export const GET_PROJECT = '@project/GET_PROJECT';
export const GET_PROJECTS = '@project/GET_PROJECTS';

export const GET_PROJECT_DETAIL = '@project/GET_PROJECT_DETAIL';

export const GET_PROJECT_BUDGET = '@project/GET_PROJECT_BUDGET';

export const GET_PROJECT_ACTUALLY = '@project/GET_PROJECT_ACTUALLY';

export const GET_PROJECT_ACTUALLY_BUDGET = '@project/GET_PROJECT_ACTUAL_BUDGET';

export const GET_PROJECT_STATISTICS = '@project/GET_PROJECT_STATISTICS';

export const GET_PROJECT_WEEKLY_TRANSITIONS = '@project/GET_PROJECT_WEEKLY_TRANSITIONS';

export const GET_PROJECT_BURNDOWNS = '@project/GET_PROJECT_BURNDOWNS';

export const CLEAR_PROJECT_BUDGETS = '@project/CLEAR_PROJECT_BUDGETS';

export const PLOT_BURNDOWN = '@project/PLOT_BURNDOWN';
